<template>
  <el-select
    v-model="selectValue"
    :multiple="multiple"
    :collapse-tags="multiple"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="placeholder"
    :remote-method="remoteMethod"
    :loading="loading"
    :size="size"
    :disabled="disabled"
    @change="onSelectChange"
    style="width: 100%"
  >
    <el-option
      v-for="item in currentOptions"
      :key="item.key"
      :label="item[keyName]"
      :value="item[valueName]"
    >
    </el-option>
    <p class="option-tips" v-if="allOptions.length > optionNumber">
      默认只显示{{ options.length }}条，共{{ allOptions.length }}条<br />
      请通过关键字搜索
    </p>
  </el-select>
</template>

<script>
import { isEmprty } from "@/utils/validate";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Array, Number, Boolean],
      default: () => {
        return null;
      },
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    keyName: {
      type: [String],
      default: "name",
    },
    valueName: {
      type: [String],
      default: "id",
    },
    size: { type: [String], default: "" },
    allOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "点击选择或输入关键字搜索",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    optionNumber: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      loading: false,
      selectValue: "",
      options: [],
    };
  },
  computed: {
    currentOptions() {
      return this.options.map((target) => {
        return {
          ...target,
          key: this.createUniqueString(),
        };
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.initValue();
      },
      immediate: true,
    },
    allOptions: {
      handler() {
        if (
          this.allOptions.length > 0 &&
          this.allOptions.length > this.optionNumber
        ) {
          this.setDefaultOptions();
        } else {
          this.options = this.allOptions;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    initValue() {
      if (!isEmprty(this.value) && !this.multiple) {
        this.selectValue = this.value;
        this.setDefaultOptions();
        return false;
      }
      if (this.multiple && this.value instanceof Array) {
        this.selectValue = this.value.length > 0 ? this.value : [];
        this.setDefaultOptions();
        return false;
      }
      this.selectValue = this.value;
      this.setDefaultOptions();
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.options = this.allOptions
          .filter((item) => {
            return (
              item[this.keyName].toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          })
          .map((item) => {
            return {
              ...item,
              key: this.createUniqueString(),
            };
          });
        this.loading = false;
      } else {
        this.setDefaultOptions();
      }
    },
    setDefaultOptions() {
      this.options = this.allOptions.slice(0, this.optionNumber);
      let optionIds = this.options.map((item) => item[this.valueName]);
      if (!this.multiple && !isEmprty(this.value)) {
        let target = this.allOptions.find(
          (item) => item[this.valueName] == this.value
        );
        if (target) {
          if (!optionIds.includes(this.value)) {
            this.options.unshift(target);
          }
        }
      } else {
        if (this.value instanceof Array) {
          if (this.value.length > 0) {
            let results = this.allOptions.filter((target) =>
              this.value.includes(target[this.valueName])
            );
            results.forEach((target) => {
              if (!optionIds.includes(target[this.valueName])) {
                this.options.unshift(target);
              }
            });
          }
        }
      }
    },
    onSelectChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    createUniqueString() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
  },
};
</script>

<style lang="scss" scoped>
.option-tips {
  box-sizing: border-box;
  padding: 5px;
  font-size: 13px;
  color: #989898;
  line-height: 24px;
  text-align: center;
  border-top: 1px solid #ddd;
  background-color: #fcfcfc;
}
</style>