<template>
  <div class="component-wrap">
    <el-tabs
      :tab-position="tabPosition"
      style="max-height: 500px"
      type="border-card"
    >
      <template v-for="item in rolesOptions">
        <el-tab-pane :label="item.groupName" :key="item.groupId">
          <el-tag
            v-for="role in item.options"
            class="role-tag"
            :type="isCheck(role) ? 'success' : 'info'"
            :checked="isCheck(role)"
            :key="`role${role.id}`"
            @click="onRoleCheck(role)"
            ><i class="el-icon-success"></i>&nbsp;{{ role.name }}
          </el-tag>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { GetUsersRoles } from "../api";
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabPosition: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      rolesOptions: [],
      allRoles: [],
      selectRoles: [],
    };
  },
  created() {
    this.getRoles();
  },
  watch: {
    value: {
      handler() {
        this.upDateValue();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    upDateValue() {
      if (this.allRoles.length > 0 && this.value.length > 0) {
        this.selectRoles = [];
        this.allRoles.forEach((item) => {
          if (this.value.includes(item.id)) {
            this.selectRoles.push(item);
          }
        });
      } else {
        this.selectRoles = [];
      }
    },
    isCheck(role) {
      return this.selectRoles.some((target) => target.id === role.id);
    },
    getRoles() {
      GetUsersRoles().then((res) => {
        this.allRoles = res.data;
        this.formatRoleOptions(res.data);
        this.upDateValue();
      });
    },
    formatRoleOptions(soreceRoles = []) {
      let roleIds = [];
      let roles = [];
      this.rolesOptions = [];
      roles.push({
        groupId: 1111111111111,
        groupName: "全部",
        options: this.allRoles,
      });
      soreceRoles.forEach((item) => {
        if (roleIds.includes(item.groupId)) {
          roles = roles.map((target) => {
            if (target.groupId === item.groupId) {
              target.options.push(item);
            }
            return target;
          });
        } else {
          roleIds.push(item.groupId);
          roles.push({
            groupId: item.groupId,
            groupName: item.groupName,
            options: [item],
          });
        }
      });
      this.rolesOptions = roles;
    },
    onRoleCheck(role) {
      if (this.selectRoles.some((target) => target.id === role.id)) {
        this.selectRoles = this.selectRoles.filter(
          (target) => target.id !== role.id
        );
      } else {
        this.selectRoles.push(role);
      }
      let ids = this.selectRoles.map((item) => item.id);
      this.$emit("input", ids);
      this.$emit("change", ids, this.selectRoles);
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrap {
  box-sizing: border-box;
  padding: 15px;
  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      max-height: 500px;
      overflow-y: auto;
    }
  }
  .role-tag {
    margin-right: 20px;
    margin-bottom: 15px;
    cursor: pointer;
  }
}
</style>